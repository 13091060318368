import FirebaseApiClient from "../api/FirebaseApiClient";

const useTicketsApi = () => {
  const client = FirebaseApiClient.getInstance();
  const ticketsApi = client.ticketsApi();

  return {
    createTicket: ticketsApi.createTicket,
    createMultipleTickets: ticketsApi.createMultipleTickets,
    // heartBeat: recepientsApi.updateRecepientStatus,
    // getById: recepientsApi.getRecepientById,
  };
};

export default useTicketsApi;
