import {
  Badge,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { LocalActivity } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import usePaymentsApi from "../data/hooks/usePayments";
import { useAppSelector } from "../data/hooks/hooks";
import { RootState } from "../data/store";

interface CheckoutData {
  eventId: string;
  eventTitle: string;
  tickets: number;
  ticketPrice: number;
  taxes?: number;
  grandTotal?: number;
  promoRef?: string;
}

function isEmail(email: string): boolean {
  return new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ).test(email);
}

export const Checkout = () => {
  const loc: Location = useLocation();
  const state = loc["state"] as any;
  const navigate = useNavigate();
  const { createDraftOrder } = usePaymentsApi();

  const [checkoutData, setCheckoutData] = useState<CheckoutData | undefined>();
  const [orderID, setOrderId] = useState<string | undefined>();
  const [email, setEmail] = useState<string>("");
  const [customerName, setName] = useState<string>("");
  const [customerPhone, setPhone] = useState<string>("");
  const activeOrder = useAppSelector((state: RootState) => state.order);

  const isProductionEnvironment = true;

  useEffect(() => {
    const timer = !state
      ? setInterval(() => {
          navigate("/", { replace: true });
        }, 100)
      : undefined;
    if (state && !checkoutData) {
      const hstGst = (state.ticketPrice / 100) * 13;
      setCheckoutData({
        eventId: state.eventId,
        ticketPrice: state.ticketPrice,
        tickets: state.tickets,
        taxes: hstGst,
        grandTotal: state.tickets * state.ticketPrice + hstGst * state.tickets,
        promoRef: state.promoRef,
        eventTitle: state.eventTitle,
      });
    }
    return () => {
      clearInterval(timer);
    };
  }, [state, checkoutData]);

  const getCongig = (isProd: boolean) => {
    return isProd
      ? {
          locationId: "LK55VTQSPCQ5S",
          appId: "sq0idp-4SB7omWIYAFNjGsnddWCkQ",
        }
      : {
          locationId: "LGDWJ1P4AMA1H",
          appId: "sandbox-sq0idb-tuyyyg0HVV0a978zV5lV1A",
        };
  };

  return (
    <div style={{ margin: 16 }}>
      <Paper elevation={2} style={{ padding: 16, margin: 16 }}>
        <div>
          <h2 style={{ color: "#d1342fff" }}>Your order:</h2>
          <p>
            <span style={{ fontSize: 22 }}>{checkoutData?.eventTitle}</span>{" "}
            event
          </p>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Badge badgeContent={checkoutData?.tickets} color="secondary">
                  <LocalActivity scale={1.2} />
                </Badge>
              </ListItemAvatar>
              <ListItemText
                primary={`Tickets: ${checkoutData?.tickets} X ${
                  checkoutData?.ticketPrice
                } CDN$ = ${
                  checkoutData?.tickets! * checkoutData?.ticketPrice!
                } CDN$`}
                secondary={
                  <div>
                    <div>
                      Shipping/Handling: <span>FREE </span>
                    </div>
                    <div>
                      GST/HST:
                      {((checkoutData?.tickets! * checkoutData?.ticketPrice!) /
                        100) *
                        13}
                      CDN$
                    </div>
                    <div style={{ fontWeight: "bold" }}>
                      Grand Total: <span>{checkoutData?.grandTotal} CDN$</span>
                    </div>
                  </div>
                }
              />
            </ListItem>
          </List>
          {orderID && !activeOrder.tickets && (
            <LinearProgress color="secondary" style={{ margin: 8 }} />
          )}

          {orderID && activeOrder.paymentStatus == 200 && (
            <div>
              <h3>Thank you! We received your payment.</h3>
              <p>
                A confirmation email will be sent to you shortly. If you can not
                find your confirmation email in your inbox, it is worth checking
                in your spam or junk mail section.
              </p>
            </div>
          )}

          {!orderID && checkoutData && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3} lg={3} />
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div style={{ padding: 16 }}>
                  <Stack
                    direction="column"
                    justifyContent="space-around"
                    alignItems="stretch"
                    spacing={2}
                  >
                    <h4>Send tickets to:</h4>
                    <Stack
                      direction="column"
                      justifyContent="space-around"
                      alignItems="stretch"
                      spacing={2}
                    >
                      <TextField
                        label="Name"
                        variant="outlined"
                        value={customerName}
                        type="text"
                        onChange={(event) => {
                          setName(event.target.value);
                        }}
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        value={email}
                        type="email"
                        onChange={(event) => {
                          setEmail(event.target.value);
                        }}
                      />
                      <TextField
                        label="Phone(optional)"
                        variant="filled"
                        value={customerPhone}
                        onChange={(event) => {
                          setPhone(event.target.value);
                        }}
                      />
                    </Stack>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="I consent to receive updates about current event changes."
                    />

                    <h4>Pay with:</h4>
                    {!isProductionEnvironment && (
                      <div style={{ background: "#f0f" }}>
                        Sandbox mode ON. Use provideded cards for validation.
                        Any upcoming exp. dates and Postal/ZIP codes are valid
                        on sandbox mode. You will not see it when production
                        credentials are applied.
                        <p>
                          Visa 4111 1111 1111 1111{" "}
                          <span style={{ fontStyle: "italic" }}>CVV 111</span>{" "}
                        </p>
                        <p>
                          Mastercard 5105 1051 0510 5100{" "}
                          <span style={{ fontStyle: "italic" }}>CVV 111</span>{" "}
                        </p>
                      </div>
                    )}

                    <PaymentForm
                      applicationId={getCongig(isProductionEnvironment).appId}
                      locationId={getCongig(isProductionEnvironment).locationId}
                      cardTokenizeResponseReceived={(props, verifiedBuyer) => {
                        if (props.status === "OK" && props.token) {
                          createDraftOrder({
                            paymentToken: props.token,
                            eventId: checkoutData.eventId,
                            amount: checkoutData.tickets,
                            paymentLocation: getCongig(isProductionEnvironment)
                              .locationId,
                            email,
                            name: customerName,
                            phone: customerPhone,
                            promouter: checkoutData?.promoRef,
                          }).then((refId) => {
                            setOrderId(refId);
                          });
                        }
                      }}
                    >
                      <CreditCard
                        buttonProps={{
                          isLoading: customerName.length < 1 || !isEmail(email),
                          onClick: () => {},
                        }}
                      >
                        Pay {checkoutData.grandTotal} CDN$
                      </CreditCard>
                    </PaymentForm>
                  </Stack>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} />
            </Grid>
          )}
        </div>
      </Paper>
    </div>
  );
};
