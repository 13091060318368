import { Location, useLocation } from "react-router-dom";
import useTicketsApi from "../data/hooks/useTicketsApi";
import { Paper } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
export const TicketsProcessor = () => {
  const loc: Location = useLocation();
  const { ticketsRefs } = loc.state as any;
  const tickets = useTicketsApi();

  console.log("tickets refs: ", ticketsRefs);
  return (
    <div>
      Tickets:
      {(ticketsRefs as string[]).map((id, index) => {
        return (
          <Paper elevation={2} style={{ padding: 16, margin: 16 }} key={id}>
            <div>Ticket {index + 1}</div>
            <div style={{ padding: "16px" }}>
              <QRCodeSVG value={id} />
            </div>
          </Paper>
        );
      })}
    </div>
  );
};
