import {
  AppBar,
  Box,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Home, Storefront } from "@material-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useUserApi } from "../data/hooks/useUserApi";
import { useAppSelector } from "../data/hooks/hooks";
import { RootState } from "../data/store";
import logo from "../assets/logo.png";

export const AppBarCmp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useUserApi();
   

  useEffect(() => {}, [location]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            onClick={() => {
              navigate("../", { replace: true });
            }}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            {/* <Storefront /> */}
            <img src={logo} alt="mnd_cntrl_logo" width={24} />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            MND CNTRL
          </Typography>
          {/* {!userInfo && (
            <Button
              color="inherit"
              onClick={() => {
                navigate("/login", { replace: true });
              }}
            >
              Login
            </Button>
          )}
          {userInfo && (
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="stretch"
              spacing={2}
            >
              <p>{userInfo.email}</p>
              <Button
                color="inherit"
                onClick={() => {
                  logout();
                }}
              >
                Logout
              </Button>
            </Stack>
          )} */}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
