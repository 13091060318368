import { useParams } from "react-router";

const PaymentIntelitix = () => {

  const { intelitixEventId } = useParams();
  return (
    <iframe
      title="xxx"
      frameBorder={0}
      width="100%"
      height="720px"
      allowTransparency
      srcDoc={`
    <!doctype html>
    <html>
    <head>
    </head>
    <body style="width:100%">
    <div id="${intelitixEventId}"></div>
    <script>
      (function(win, doc, opts) {
          win.cbw49258 = opts;
          var url = '//widget.crowdblink.com/loader.js';       
          var s = doc.createElement("script");
          s.type = 'text/javascript';
          s.src = [url,'?','t=',encodeURIComponent(Math.floor((Date.now?Date.now():new Date())/1000))].join('');
          s.id='script-${intelitixEventId}'
          doc.write(s.outerHTML);
      })(window, document, ['${intelitixEventId}', 'prd', 'false', '#000000', '#ffffff']);
    </script>
    </body>
    </html>
    `}
    />
  );
};

export default PaymentIntelitix;
