import { FirebaseApp } from "firebase/app";
import {
  Auth,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  User,
  UserCredential,
} from "firebase/auth";
import { updateUserInfo } from "../reducers/userSlice";
import { store } from "../store";

export interface UserApi {
  signIn: (email: string, password: string) => void;
  signUp: (email: string, password: string) => void;
  logOut: () => void;
}

export class UserApiImpl implements UserApi {
  private auth: Auth;
  constructor(firebaseApp: FirebaseApp) {
    this.auth = getAuth(firebaseApp);
    this.auth.onAuthStateChanged(this.onAuthStateChanged);
  }

  onAuthStateChanged(user: User | null) {
    store.dispatch(updateUserInfo(JSON.stringify(user)));
  }

  logOut = (): void => {
    signOut(this.auth);
  };

  signUp = (email: string, password: string): void => {
    createUserWithEmailAndPassword(this.auth, email, password)
      .then((creds: UserCredential) => {
        console.log("login", creds);
      })
      .catch((error) => {
        console.log("login error", error);
      });
  };

  signIn = (email: string, password: string): void => {
    signInWithEmailAndPassword(this.auth, email, password)
      .then((creds: UserCredential) => {
        console.log("login", creds);
      })
      .catch((error) => {
        console.log("login error", error);
      });
  };
}
