import { Button, Paper, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { useUserApi } from "../data/hooks/useUserApi";

export const LoginScreen = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const user = useUserApi();
  return (
    <div>
      <Paper elevation={2} style={{ padding: 16, margin: 16 }}>
        <Stack>
          <TextField
            label="Email"
            variant="standard"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <TextField
            label="Password"
            variant="standard"
            type="password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
          <Button
            onClick={() => {
              if (email.length > 0 && password.length > 0) {
                user.signIn(email, password);
              }
            }}
          >
            Login
          </Button>
        </Stack>
      </Paper>
    </div>
  );
};
