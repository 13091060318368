import { Grid, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { StoreItemComponent } from "../components/StoreItemComponent";
import useEventsApi from "../data/hooks/useEventsApi";
import { EventItem } from "../data/objects/Item";

export const Storefront = () => {
  const navigate = useNavigate();
  const eventsApi = useEventsApi();
  const [events, setAvailableEvents] = useState<EventItem[] | undefined>();

  useEffect(() => {
    if (!events) {
      eventsApi.getAllEvents().then((evnts) => {
        evnts.sort((e1, e2) =>
          (e1.date as any)["seconds"] > (e2.date as any)["seconds"] ? -1 : 1
        );
        setAvailableEvents(evnts);
        console.log(events);
      });
    }
  }, [events, eventsApi]);

  return (
    <>
      {!events && <LinearProgress color="secondary" />}
      <Helmet>
        <title>MND CNTRL ORG</title>
        <meta charSet="utf-8" />
        <meta property="og:type" key="og:type" content="website" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="title" key="title" content="MND CNTRL PRESENT" />
        {/* <meta name="description" content={eventInfo.date.toDateString()} /> */}
        <meta property="og:image" key="og:image" content="logo512.png" />
      </Helmet>
      <Grid container spacing={2}>
        {events?.map((ev) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={ev.id}>
              <StoreItemComponent event={ev} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
