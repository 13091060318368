import { Margin } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EventItem } from "../data/objects/Item";

export interface StoreItemComponentProps {
  event: EventItem;
  onSelected?: (id: string) => void;
}

export const StoreItemComponent = (props: StoreItemComponentProps) => {
  const navigate = useNavigate();
  const [itemHover, setItemHover] = useState(false);
  // const [isPastEvent, setIsPastEvent] = useState(true);
  const { event } = props;
  const isPastEvent = event.status === "past";

  return (
    <div style={{ margin: 8, opacity: event.status === "upcoming" ? 1 : 0.5 }}>
      <Container maxWidth="sm">
        <div
          onMouseEnter={(e) => {
            setItemHover(true);
          }}
          onMouseLeave={(e) => {
            setItemHover(false);
          }}
          onClick={() => {
            console.log("event ");
          }}
        >
          <Card sx={{ maxWidth: 345 }} elevation={itemHover ? 1 : 4}>
            <CardMedia
              style={{ padding: 8 }}
              component="img"
              height="240"
              image={event.imageUrl}
              alt={event.name}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {event.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {event.description?.substring(0, 140) + "..."}
              </Typography>
              <Typography variant="body1" color="text.primary">
                {isPastEvent ? "SOLD" : `${event.price} $CAD`}
              </Typography>
            </CardContent>
            <CardActions>
              {/* <Button size="small">Share</Button> */}
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  navigate(`../event/${event.id}`, { replace: false });
                }}
              >
                {isPastEvent ? "Info" : "Tickets"}
              </Button>
            </CardActions>
          </Card>
        </div>
      </Container>
    </div>
  );
};
