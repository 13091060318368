import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useEventsApi from "../data/hooks/useEventsApi";
import { EventItem } from "../data/objects/Item";

export const ManageEvents = () => {
  const [events, loading] = useAsyncEventsFetchHook();
  const navigate = useNavigate();
  return (
    <div>
      <Stack style={{ margin: 16 }}>
        <Button
          variant="outlined"
          onClick={() => {
            navigate("/editEvent", { replace: true });
          }}
        >
          + Create event
        </Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Event name</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(events as EventItem[]).map((event) => (
              <TableRow
                key={event.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {event.id}
                </TableCell>
                <TableCell align="right">{event.status}</TableCell>
                <TableCell align="right">{event.price}</TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => {
                      navigate("/editEvent", {
                        replace: true,
                        state: {
                          eventId: event.id,
                        },
                      });
                    }}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const useAsyncEventsFetchHook = () => {
  const eventsApi = useEventsApi();

  const [events, setResult] = useState<EventItem[]>([]);
  const [loading, setLoading] = useState("false");

  useEffect(() => {
    async function fetchEvents() {
      try {
        setLoading("true");
        const events = await eventsApi.getAllEvents();
        setResult(events);
      } catch (error) {
        setLoading("null");
      }
    }
    fetchEvents();
  }, []);

  return [events, loading];
};
