import "./App.css";

import { AppBarCmp } from "./components/AppBarCmp";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { Storefront } from "./screens/Storefront";
import { Routes, Route, Navigate } from "react-router-dom";
import { About } from "./screens/About";
import { StoreItemDescription } from "./screens/StoreItemDescription";
import { Checkout } from "./screens/Checkout";
import { TicketsProcessor } from "./screens/TicketsProcessor";
import { LoginScreen } from "./screens/LoginScreen";
import { useUserApi } from "./data/hooks/useUserApi";
import { ManageEvents } from "./admin/ManageEvents";
import { store } from "./data/store";
import { Provider } from "react-redux";
import { EditEvent } from "./admin/EditEvent";
import { CookiesProvider } from "react-cookie";
import { Footer } from "./components/Footer";
import PaymentIntelitix from "./screens/PaymentIntelitix";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Helvetica",
      '"Neue Haas Grotesk Text Bd"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  palette: {
    background: { default: "#fff", paper: "#fff" },
    primary: {
      main: "#0a0a0aff",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#d1342fff",
    },
  },
});

const App = () => {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <AppContent />
      </Provider>
    </CookiesProvider>
  );
};

const AppContent = () => {
  const { userInfo } = useUserApi();

  type ReqAuthProps = {
    children: JSX.Element;
  };

  function RequireAuth({ children }: ReqAuthProps): JSX.Element {
    return children; //userInfo === null ? <Navigate to="/login" replace /> :
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBarCmp />
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/store" element={<Storefront />} />
        <Route path="/" element={<Storefront />} />
        <Route path="/event">
          <Route path=":itemId" element={<StoreItemDescription />} />
        </Route>
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/tickets" element={<TicketsProcessor />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/pay">
          <Route path=":intelitixEventId" element={<PaymentIntelitix />} />
        </Route>
        <Route
          path="/manageEvents"
          element={
            <RequireAuth>
              <ManageEvents />
            </RequireAuth>
          }
        />
        <Route
          path="/editEvent"
          element={
            <RequireAuth>
              <EditEvent />
            </RequireAuth>
          }
        />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
};

export default App;
