import { Order, Ticket, TicketOrderOnfo } from "../objects/Payments";
import {
  doc,
  Firestore,
  addDoc,
  collection,
  writeBatch,
} from "firebase/firestore";

export interface TicketsApi {
  createTicket: (ticketOrderInfo: TicketOrderOnfo) => Promise<string>;
  createMultipleTickets: (ticketOrderInfo: TicketOrderOnfo[]) => string[];
}

export class TicketsApiImpl implements TicketsApi {
  private firestore: Firestore;
  constructor(firestore: Firestore) {
    this.firestore = firestore;
  }
  createTicket = async (ticketOrderInfo: TicketOrderOnfo): Promise<string> => {
    const ticketsDoc = doc(this.firestore, "tickets", ticketOrderInfo.eventId);
    const col = collection(ticketsDoc, "paidTickets");
    const ticketRef = await addDoc(col, { ticketOrderInfo });
    return ticketRef.id;
  };

  createMultipleTickets = (ticketOrderInfo: TicketOrderOnfo[]): string[] => {
    const refs: string[] = [];
    ticketOrderInfo.forEach(async (order) => {
      const ticketsDoc = doc(this.firestore, "tickets", order.eventId);
      const col = collection(ticketsDoc, "paidTickets");
      const ticketRef = await addDoc(col, { order });
      refs.push(ticketRef.id);
    });
    return refs;
  };
}
