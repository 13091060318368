import FirebaseApiClient from "../api/FirebaseApiClient";

const useEventsApi = () => {
  const client = FirebaseApiClient.getInstance();
  const eventsApi = client.eventsApi();

  return {
    createEvent: eventsApi.createEvent,
    getAllEvents: eventsApi.getAllEvents,
    getEventInfo: eventsApi.getEventInfo,
  };
};

export default useEventsApi;
