import { AddCircle, RemoveCircle } from "@material-ui/icons";
import { Payment } from "@mui/icons-material";
import {
  Button,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { RecordsPlayer } from "../components/RecordsPlayer";
import { useAppDispatch, useAppSelector } from "../data/hooks/hooks";
import useEventsApi from "../data/hooks/useEventsApi";
import { EventItem } from "../data/objects/Item";
import { updatePromoRef } from "../data/reducers/promoSlice";
import { Helmet } from "react-helmet";

export const StoreItemDescription = () => {
  let { itemId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const eventsApi = useEventsApi();
  const [ticketsOrder, setTicketsOrder] = useState<number>(1);
  const [eventInfo, setEventInfo] = useState<EventItem>();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryPref = searchParams.get("prf");
  const promoRef = useAppSelector((state) => state.promo.promoReference);

  useEffect(() => {
    if (queryPref && !promoRef) {
      dispatch(updatePromoRef(queryPref));
      searchParams.delete("prf");
      setSearchParams(searchParams);
    }
  }, [queryPref, promoRef, dispatch, searchParams, setSearchParams]);

  useEffect(() => {
    if (itemId) {
      eventsApi.getEventInfo(itemId).then((evnt) => {
        setEventInfo(evnt);
      });
    }
  }, [eventsApi, itemId]);

  return (
    <Container>
      {eventInfo && (
        <Helmet>
          <title>{eventInfo.name}</title>
          <meta charSet="utf-8" />
          <meta property="og:type" key="og:type" content="website" />
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          <meta name="title" key="title" content={eventInfo.name} />
          <meta name="description" content={eventInfo.date.toDateString()} />
          <meta
            property="og:image"
            key="og:image"
            content={eventInfo.imageUrl}
          />
        </Helmet>
      )}
      <div style={{ padding: 16 }}>
        {!eventInfo && <LinearProgress color="secondary" />}
        <h2>{eventInfo?.name}</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <Stack
              direction="column"
              justifyContent="space-around"
              alignItems="stretch"
              spacing={2}
            >
              <img src={eventInfo?.imageUrl} width="100%" />
              {/* {eventInfo?.status === "past" && <RecordsPlayer />} */}
              <h4> Event description </h4>
              <div> {eventInfo?.description}</div>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                Event date:
                <span style={{ fontStyle: "italic" }}>
                  {eventInfo?.date.toDateString()}
                </span>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Paper elevation={4} style={{ padding: 16 }}>
              {eventInfo?.status === "upcoming" && (
                <h3>Tickets {eventInfo?.price}$ CAD</h3>
              )}
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                {eventInfo?.intellitixTicketingID ? (
                  <Button
                    disabled={eventInfo?.status === "past"}
                    variant="contained"
                    color="secondary"
                    endIcon={<Payment />}
                    onClick={() => {
                      navigate(
                        `../../pay/${eventInfo?.intellitixTicketingID}`,
                        {
                          replace: false,
                        }
                      );
                    }}
                  >
                    Tickets
                  </Button>
                ) : (
                  <Button
                    disabled={eventInfo?.status === "past"}
                    variant="contained"
                    color="secondary"
                    endIcon={<Payment />}
                    onClick={() => {
                      navigate(`../../checkout`, {
                        replace: true,
                        state: {
                          eventId: itemId,
                          eventTitle: eventInfo?.name,
                          tickets: ticketsOrder,
                          ticketPrice: eventInfo?.price,
                          promoRef,
                        },
                      });
                    }}
                  >
                    Buy now
                  </Button>
                )}
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};
