import { Order, Ticket } from "../objects/Payments";
import {
  doc,
  Firestore,
  updateDoc,
  setDoc,
  addDoc,
  collection,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { EventItem, NewEventData } from "../objects/Item";

export interface EventsApi {
  createEvent: (event: NewEventData) => Promise<void>;
  getAllEvents: () => Promise<EventItem[]>;
  getEventInfo: (eventId: string) => Promise<EventItem | undefined>;
}

export class EventsApiImpl implements EventsApi {
  private firestore: Firestore;
  constructor(firestore: Firestore) {
    this.firestore = firestore;
  }

  createEvent = async (event: NewEventData): Promise<void> => {
    const eventsRef = collection(this.firestore, "events");
    await addDoc(eventsRef, event);
  };

  getAllEvents = async (): Promise<EventItem[]> => {
    const events = await getDocs(collection(this.firestore, "events"));
    const items: EventItem[] = [];
    events.forEach((event) => {
      const data = event.data() as NewEventData;
      items.push({ id: event.id, ...data, price: data.price / 100 });
    });
    return items;
  };

  getEventInfo = async (eventId: string): Promise<EventItem> => {
    const eventsDoc = doc(this.firestore, "events", eventId);
    const docSnap = await getDoc(eventsDoc);
    const event = docSnap.data() as EventItem;
    const d = new Date(1970, 0, 1);
    d.setSeconds((event?.date as any)["seconds"]);
    event.date = d;
    return { ...event, price: event.price / 100 };
  };
}
