import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "firebase/auth";
import { RootState } from "../store";

interface UserState {
  userInfo: User | null;
}

const initialState: UserState = {
  userInfo: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserInfo: (state, action) => {
      state.userInfo = JSON.parse(action.payload);
    },
  },
});

export const { updateUserInfo } = userSlice.actions;
export const selectUser = (state: RootState) => state.user.userInfo;

export default userSlice.reducer;
