import FirebaseApiClient from "../api/FirebaseApiClient";

const usePaymentsApi = () => {
  const client = FirebaseApiClient.getInstance();
  const paymentsApi = client.paymentsApi();
  //   const recepients = useSelector(
  //     (state: RootState) => state.recepients.recepients,
  //   );

  //   useEffect(() => {
  //     if (!recepients) {
  //       recepientsApi.getAllRecepients(false);
  //     } else {
  //       console.log(recepients);
  //     }
  //   }, [recepients]);

  return {
    createDraftOrder: paymentsApi.createDraftOrder,
    // heartBeat: recepientsApi.updateRecepientStatus,
    // getById: recepientsApi.getRecepientById,
  };
};

export default usePaymentsApi;
