import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "firebase/auth";
import { Order } from "../objects/Payments";
import { RootState } from "../store";

interface ActiveOrderStatus {
  order: Order | null;
  paymentStatus?: number;
  tickets?: string[];
}

const initialState: ActiveOrderStatus = {
  order: null,
};

export const userSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    updateActiveOrder: (state, action) => {
      const status: ActiveOrderStatus = JSON.parse(action.payload);
      state.order = status.order;
      state.paymentStatus = status.paymentStatus;
      state.tickets = status.tickets;
    },
  },
});

export const { updateActiveOrder } = userSlice.actions;
export const selectActiveOrder = (state: RootState) => state.order.order;

export default userSlice.reducer;
