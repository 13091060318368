import { NewOrderData, Order } from "../objects/Payments";
import {
  doc,
  Firestore,
  updateDoc,
  setDoc,
  collection,
  addDoc,
  onSnapshot,
  DocumentReference,
} from "firebase/firestore";
import { store } from "../store";
import { updateActiveOrder } from "../reducers/orderSlice";

export interface PaymentsApi {
  createDraftOrder: (order: NewOrderData) => Promise<string>;
}

export class PaymentsApiImpl implements PaymentsApi {
  private firestore: Firestore;
  private activeOrderRef: DocumentReference | null = null;
  constructor(firestore: Firestore) {
    this.firestore = firestore;
  }

  supscribe() {
    if (this.activeOrderRef != null) {
      onSnapshot(this.activeOrderRef, (doc) => {
        store.dispatch(updateActiveOrder(JSON.stringify(doc.data())));
      });
    }
  }

  createDraftOrder = async (order: NewOrderData): Promise<string> => {
    const paymentsDocRef = collection(this.firestore, "orders");
    const ref = await addDoc(paymentsDocRef, {
      order: order,
      createdAt: new Date(),
    });
    this.activeOrderRef = ref;
    this.supscribe();
    return ref.id;
  };
}
