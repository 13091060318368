import FirebaseApiClient from "../api/FirebaseApiClient";
import { RootState } from "../store";
import { useAppSelector } from "./hooks";

export const useUserApi = () => {
  const client = FirebaseApiClient.getInstance();
  const userApi = client.userApi();
  const userInfo = useAppSelector((state: RootState) => state.user.userInfo);
  
  return {
    userInfo,
    signUp: userApi.signUp,
    signIn: userApi.signIn,
    logout: userApi.logOut,
  };
};
