import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "firebase/auth";
import { RootState } from "../store";

interface PromoState {
  promoReference: string | null;
}

const initialState: PromoState = {
  promoReference: null,
};

export const promoSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updatePromoRef: (state, action) => {
      state.promoReference = action.payload;
    },
  },
});

export const { updatePromoRef } = promoSlice.actions;
export const selectPromoRef = (state: RootState) => state.promo.promoReference;
export default promoSlice.reducer;
