import {
  Paper,
  Stack,
  TextField,
  Button,
  TextareaAutosize,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useEventsApi from "../data/hooks/useEventsApi";

export const EditEvent = () => {
  const [eventName, setEventName] = useState<string>("");
  const [eventDescription, setEventDescription] = useState<string>("");
  const [eventDate, setEventDate] = useState<string>("");
  const [eventTicketPrice, setEventTicketPrice] = useState<number>(0);
  const eventsApi = useEventsApi();
  const navigate = useNavigate();

  return (
    <>
      <Paper elevation={2} style={{ padding: 16, margin: 16 }}>
        <Stack spacing={2}>
          <TextField
            label="Event Title"
            variant="standard"
            value={eventName}
            onChange={(event) => {
              setEventName(event.target.value);
            }}
          />
          <TextareaAutosize
            aria-label="minimum height"
            minRows={6}
            placeholder="Event Description"
            value={eventDescription}
            onChange={(event) => {
              setEventDescription(event.target.value);
            }}
          />

          <TextField
            label="Event time and date"
            type="datetime-local"
            defaultValue={new Date().toISOString().substring(0, 16)}
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              setEventDate(event.target.value);
            }}
          />

          <TextField
            label="Ticket price $"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            value={eventTicketPrice}
            onChange={(event) => {
              setEventTicketPrice(Number(event.target.value));
            }}
          />

          <Button
            onClick={() => {
              eventsApi
                .createEvent({
                  name: eventName,
                  description: eventDescription,
                  date: new Date(eventDate),
                  status: "upcoming",
                  price: eventTicketPrice,
                })
                .then(() => {
                  navigate("/manageEvents", { replace: true });
                })
                .catch((error) => {
                  console.log(`Error : ${error}`);
                });
            }}
          >
            Create
          </Button>
        </Stack>
      </Paper>
    </>
  );
};
