import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { Firestore, getFirestore } from "firebase/firestore";
import { firebaseConfig } from "../../firebase";
import Api from "./Api";
import { EventsApi, EventsApiImpl } from "./EventsApi";
import { PaymentsApi, PaymentsApiImpl } from "./PaymentsApi";
import { TicketsApi, TicketsApiImpl } from "./TicketsApi";
import { UserApi, UserApiImpl } from "./UserApi";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

abstract class FirebaseClient {
  protected readonly firebaseApp: FirebaseApp;
  protected readonly firestore: Firestore;

  public constructor(config: FirebaseOptions) {
    this.firebaseApp = initializeApp(config);
    this.firestore = getFirestore();
    const analytics = getAnalytics(this.firebaseApp);
    const appCheck = initializeAppCheck(this.firebaseApp, {
      provider: new ReCaptchaV3Provider('6LdYxychAAAAAOmFP6sr9GstsWcGkysJ22Ik1dnj'),
      isTokenAutoRefreshEnabled: true
    });
  }
}

class FirebaseApiClient extends FirebaseClient implements Api {
  private static classInstance: FirebaseApiClient;

  private mUserApi: UserApiImpl;
  private mPaymentsApi: PaymentsApiImpl;
  private mTicketsApi: TicketsApiImpl;
  private mEventsApi: EventsApiImpl;

  private constructor() {
    super(firebaseConfig);
    this.mUserApi = new UserApiImpl(this.firebaseApp);
    this.mPaymentsApi = new PaymentsApiImpl(this.firestore);
    this.mTicketsApi = new TicketsApiImpl(this.firestore);
    this.mEventsApi = new EventsApiImpl(this.firestore);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new FirebaseApiClient();
    }
    return this.classInstance;
  }

  userApi = (): UserApi => {
    return this.mUserApi;
  };

  paymentsApi = (): PaymentsApi => {
    return this.mPaymentsApi;
  };

  ticketsApi = (): TicketsApi => {
    return this.mTicketsApi;
  };

  eventsApi = (): EventsApi => {
    return this.mEventsApi;
  };
}

export default FirebaseApiClient;
